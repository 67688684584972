<template>
  <div>
    <div class="title-doc-b2b">
      <p class="title-secondary color__blue_main fw__bold">{{ $t('documents.file_set') }}</p>
    </div>
    <div class="page-main-content page-list-data">
      <div class="columns setting-role m__top--0">
        <div class="column  is-3  pt-0  mb-0">
          <button class="button btn-save-small p__left--10 p__right--10 btn-permission" v-if="(userInfo && userInfo.role_id !== 4)"
                  @click="toggleSettingRole('modal-setting-role')">
            {{ $t('documents.modal.setting_role.button') }}
          </button>
        </div>
      </div>
      <div class="columns pt-3 is-mobile">
        <div class="column  pt-0  mb-0 service-user-name">
          <span class="input-title fs-12 color__gray_text_1">{{ $t('documents.user_name.label') }}</span>
          <span class="name ml-5 fs-12 color__gray_text_1">{{ serviceUserName }}</span>
        </div>
      </div>
      <div class="columns d-flex">
        <div
            class="column p__bottom--0 p__top--0 is-align-self-flex-end is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
          <span class="vertical-baseline-middle fs-12 color__gray_text_1">全{{ paginate.total || 0 }}件</span>
        </div>
        <div
            class="column p__bottom--0 p__top--0 has-text-right align-items-center is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
          <span class="m__right--5 vertical-baseline-middle fs-12 color__gray_text_1">{{ $t('page.page-range') }}</span>
          <div class="dropdown is-right"
               id="dropdown-pagination"
               v-click-out="closeDropPageRange">
            <div class="dropdown-trigger">
              <button class="button btn-change-page-range p__top--0 p__bottom--0"
                      aria-haspopup="true"
                      @click="toggleDropPageRange()">
                <span class="w--80 has-text-right p__right--5 fs-12 color__gray_text_1">{{ paginate.perPage }} 件</span>
                <span class="icon is-small">
              <img width="12px" src="@/assets/svgs/icon_drop.svg">
            </span>
              </button>
            </div>
            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a @click="changePageRange(e)"
                   v-for="(e, i) in pageRanges"
                   :key="'page-range-' + i"
                   class="dropdown-item fs-12 color__gray_text_1">
                  {{ e }} 件
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column p__top--10">
          <div class="table-container data-table">
            <table class="table is-striped is-hoverable is-fullwidth">
              <thead>
              <tr>
                <th class="input-title pl-5">{{ $t('search_documents.list.name') }}</th>
                <th class="input-title">{{ $t('search_documents.list.office_name') }}</th>
                <th class="input-title">{{ $t('search_documents.list.owner') }}</th>
                <th class="input-title">{{ $t('search_documents.list.create_at') }}</th>
                <th class="input-title">{{ $t('search_documents.list.updated_at') }}</th>
                <th class="input-title input-tags">{{ $t('search_documents.list.tag') }}</th>
                <th class="input-drop"></th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(e, i) in documentsClone">
                <tr class="document-row cursor-pointer" :class="{'is-active': e.openChild}" @click="goToDetail(e.id)"
                    :key="'document-' + i">
                  <td>{{ e.name }}</td>
                  <td>{{
                      e.office && e.office.division.branch.name + ' > ' + e.office.division.name + ' > ' +
                      e.office.name
                    }}
                  </td>
                  <td>{{ e.owner && e.owner.name }}</td>
                  <td>{{ momentFormat(e.created_at) }}</td>
                  <td>{{ momentFormat(e.updated_at) }}</td>
                  <td>
                    <span class="tag m--5" v-for="(tag, index) in e.tags.slice(0,3)"
                          :key="'file-' + i + '-' + index">{{ tag.name }}
                    </span>
                    <span :class="{'d-none': e.tags.length < 3}">...</span>
                  </td>
                  <td @click.stop="toggleChild(e.id)">
                    <span class="title-table drop-document-1" v-if="e.files && e.files.length">
                      <span class="icon is-small icon-caret-down">
                        <i class="fas fa-caret-down color__pink" aria-hidden="true"></i>
                      </span>
                    </span>
                  </td>
                </tr>
                <template v-if="e.files && e.files.length">
                  <template v-if="e.openChild">
                    <tr class="file-row-1" :key="'file-' + i + '-' + index" v-for="(file, index) in e.files">
                      <td class="has-word-break" colspan="7">
                        <span class="pl-5">
                          <img src="@/assets/svgs/Files/ic_doc.svg" class="icon-file m__right--10 m__left--20"
                               v-if="file.file_format * 1 === 1">
                          <img src="@/assets/svgs/Files/ic_PDF.svg" class="icon-file m__right--10 m__left--20"
                               v-if="file.file_format * 1 === 2">
                          <img src="@/assets/svgs/Files/ic_image.svg" class="icon-file m__right--10 m__left--20"
                               v-if="file.file_format * 1 === 3">
                          {{ file.original_name }}
                        </span>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
              </tbody>
              <tr v-if="!documentsClone || !documentsClone.length">
                <td colspan="7" class="has-text-centered">{{ $t('target_not_found') }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full has-text-centered m__top--10">
          <pagination
              :current="paginate.currentPage"
              :per-page="paginate.perPage"
              :total="paginate.total"
              @change="changePage($event)">
          </pagination>
        </div>
      </div>
      <div class="modal modal-change-permission" id="modal-setting-role">
        <div class="modal-background"></div>
        <div class="modal-card" v-click-out="() => {closeModal('modal-setting-role'); resetData()}">
          <div class="modal-card-head background__gray_dark">
            <p class="modal-card-title input-title color__blue_main text-center fs-20">{{ $t('documents.detail.button.role') }}</p>
            <button class="delete" aria-label="close" @click="closeModal('modal-setting-role')"></button>
          </div>
          <section class="modal-card-body change-role-office "
                   @keyup.enter="submitSettingRole()">
            <div class="modal-field-content">
              <div class="m__bottom--18">
                <div class="m__bottom--6">
                  <label class="label input-title fs-12 color__blue_button">組織</label>
                </div>
                <div class="">
                  <multiselect_permission :list-data="offices"
                               ref="officesMultiselect"
                               :selected="selectedOffices.map(e => e.id)"
                               :placeholder="officePlaceholder"
                               @change="getSelectedOffices($event)"></multiselect_permission>
                </div>
              </div>
              <div class="m__bottom--18" v-for="(e, i) in selectedOffices" :key="'selected-office-' + i">
                <div class="m__bottom--6">
                  <label class="label fs-12 color__blue_button">{{ e.name }}</label>
                </div>
                <div class="d-flex is-align-items-flex-start is-justify-content-space-between">
                  <div style="min-width: 90%">
                    <multiselect_permission :list-data="positionStaffs"
                                            :ref="`multiSelect${i}`"
                                            :placeholder="positionPlaceholder"
                                            :selected="e.roleSetting && e.roleSetting.positions"
                                            @change="getSelectedPositions($event, e)">
                    </multiselect_permission>
                  </div>
                  <div class="">
                    <button class="btn-delete-permission blob is-action p-0" @click.stop="removeOffice(e.id)">
                      <img width="25px" src="@/assets/svgs/DocDetail/ic_delete.svg"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="modal-card-foot is-justify-content-center background__white">
            <button @click="submitSettingRole()"
                    class="button background__blue_main close-modal p-0 btn-save">
              {{ $t('buttons.submit') }}
            </button>
            <button @click="closeModal('modal-setting-role'); resetData()"
                    class="button close-modal btn-back background__white_pink">
              {{ $t('buttons.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentService from "@/services/DocumentService";
import User from '@/mixins/user'
import {momentFormat} from "@/filters";
import findIndex from 'lodash/findIndex'
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
import i18n from "@/lang/i18n";

export default {
  name: "BtoC",
  mixins: [User],
  data() {
    return {
      paginate: {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null
      },
      pageRanges: [10, 20, 50, 100],
      officeChange: {},
      positionChange: {},
      documents: [],
      documentsClone: [],
      serviceUserName: '',
      selectedOffices: [],
      docPermission: [],
      submitAvailable: true,
    }
  },
  computed: {
    docId() {
      return this.$route.params.id
    },
    serviceUserId() {
      return this.$route.query.sid
    },
  },
  watch: {
    offices(val) {
      if (val) {
        if (this.docPermission && this.docPermission) {
          this.docPermission.map((e) => {
            let index = findIndex(val, el => el.id === e.office_id)
            if (index >= 0) {
              let positions = []
              e.positions_id.split(',').map((el) => {
                positions = [...positions, ...[el * 1]]
              })
              let roleSetting = {
                office: e.office_id,
                positions
              }
              this.selectedOffices = [...this.selectedOffices, ...[{...val[index], roleSetting}]]
            }
          })
          localStorage.setItem('selectedOffices', JSON.stringify(this.selectedOffices))
        }
      }
    }
  },
  methods: {
    resetData() {
      this.selectedOffices = JSON.parse(localStorage.getItem('selectedOffices')) || []
    },
    isSelected(id) {
      let flag = false
      if (this.selectedOffices) {
        this.selectedOffices.map((e) => {
          if (e.id === id) {
            flag = true
          }
        })
      }
      return flag
    },
    getSelectedOffices(e) {
      e.map((el) => {
        let index = findIndex(this.selectedOffices, a => a.id === el.id)
        if (index < 0) {
          el.roleSetting = {
            office: el.id,
            positions: [],
          }
          this.selectedOffices = [...this.selectedOffices, ...[el]]
        }
      })
      this.selectedOffices.map((el) => {
        let index = findIndex(e, a => a.id === el.id)
        if (index < 0) {
          this.removeOffice(el.id)
        }
      })
      // if (this.isSelected(e.id)) {
      //   let index = findIndex(this.selectedOffices, (el) => el.id === e.id)
      //   this.selectedOffices.splice(index, 1)
      // } else {
      //   this.selectedOffices = [...this.selectedOffices, ...[e]]
      // }
      // this.closeDropdown('dropdown-office')
      // this.closeDropdown('dropdown-file-versions')
    },
    removeOffice(id) {
      let index = findIndex(this.selectedOffices, e => e.id === id)
      if (index >= 0) {
        this.selectedOffices.splice(index, 1)
      }
      this.selectedOffices.map((e, i) => {
        this.$refs[`multiSelect${i}`][0].updateSelected(e.roleSetting.positions)
      })
      if (!this.selectedOffices || !this.selectedOffices.length) {
        this.$refs['officesMultiselect'].updateSelected([])
      }
    },
    getSelectedPositions(event, e) {
      let index = findIndex(this.selectedOffices, el => el.id === e.id)
      if (index >= 0) {
        let positions = []
        event.map((el) => {
          positions = [...positions, ...[el.id]]
        })
        this.selectedOffices[index].roleSetting = {
          office: e.id,
          positions,
        }
      }
    },
    submitSettingRole() {
      if (this.submitAvailable) {
        let data = []
        this.selectedOffices.map((e) => {
          data = [...data, ...[e.roleSetting]]
        })
        this.submitAvailable = false
        DocumentService.settingFileAccess(this.serviceUserId, data)
            .then(() => {
              this.submitAvailable = true
              this.getDocuments(this.paginate.currentPage, this.paginate.perPage)
              localStorage.setItem('selectedOffices', JSON.stringify(this.selectedOffices))
              this.$toast.success(i18n.t('documents.toast.success'))
              this.closeModal('modal-setting-role')
            })
            .catch(() => {
              this.submitAvailable = true
              this.$toast.error(i18n.t('documents.detail.setting_role_fails'))
              this.closeModal('modal-setting-role')
            })
      }
    },
    momentFormat(date, format = 'YYYY-MM-DD') {
      return momentFormat(date, format)
    },
    async getDocuments(page, limit) {
      await DocumentService.list(this.serviceUserId, page, limit).then((res) => {
        if (res && res.data) {
          this.serviceUserName = res.data.name
          this.documents = res.data.documents ? res.data.documents.data : []
          this.documents.map((e) => {
            e.openChild = false
          })
          this.documentsClone = [...this.documents]
          this.paginate = {
            currentPage: res.data.documents.current_page,
            lastPage: res.data.documents.last_page,
            perPage: res.data.documents.per_page * 1 <= 100 ? res.data.documents.per_page * 1 : 10,
            total: res.data.documents.total,
          }
          this.docPermission = res.data.permission
          if (this.docPermission && this.docPermission.length) {
            this.selectedOffices = []
            this.docPermission.map((e) => {
              let index = findIndex(this.offices, el => el.id === e.office_id)
              if (index >= 0) {
                let positions = []
                e.positions_id.split(',').map((el) => {
                  positions = [...positions, ...[el * 1]]
                })
                let roleSetting = {
                  office: e.office_id,
                  positions
                }
                this.selectedOffices = [...this.selectedOffices, ...[{...this.offices[index], roleSetting}]]
              }
            })
            localStorage.setItem('selectedOffices', JSON.stringify(this.selectedOffices))
          }
        }
      }).catch((err) => {
        console.log(err)
        this.$toast.error('Can not get documents')
      })
    },
    toggleChild(id) {
      let index = findIndex(this.documents, (e) => e.id === id)
      if (index >= 0) {
        this.documents[index].openChild = !this.documents[index].openChild
      }
      this.documentsClone = [...this.documents]
    },
    goToDetail(id) {
      this.$router.push({name: 'BtoB', params: {id}}, () => {
      })
    },
    toggleSettingRole(id) {
      this.getDocuments()
      this.toggleModal(id)
      setTimeout(() => {
        this.modalOpened = true
      })
    },
    closeDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.remove('is-active')
    },
    toggleDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.toggle('is-active')
    },
    changePageRange(range) {
      this.paginate.perPage = range
      this.closeDropPageRange()
      this.getDocuments(1, this.paginate.perPage)
    },
    changePage(page) {
      this.getDocuments(page, this.paginate.perPage)
    },
  },
  created() {
    this.getData('offices')
    this.getData('positions')
    let prev = localStorage.getItem('prev')
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, prev || 'folders')
    this.paginate.perPage = 10
    this.getDocuments(1, this.paginate.perPage)
    localStorage.removeItem('selectedOffices')
  }
}
</script>

<style lang="scss" scoped>
.title-doc-b2b {
  padding: 10px 60px;
  background-color: $blue_document;
  border-radius: 10px 10px 0 0;
}
.page-main-content {
  padding: 40px 60px;
  .columns{
    margin: 0px;
    .column {
      padding-left: 0;
    }
  }
}
.button {
  &.btn-permission {
    border-radius: 17px !important;
    background-color: $blue_main;
    border: none !important;
    font-size: 12px;
  }
}
.btn-delete-permission {
  border: none !important;
  box-shadow: none !important;
}
.modal {
  .modal-card {
    max-width: 1024px;
    width: 100%;
    border-radius: 25px;

    .modal-card-body {
      min-height: 400px;
      overflow: hidden;

      .modal-field-content {
        padding: 0 20px;

        .dropdown-content {
          max-height: 200px;
          overflow-y: scroll;
        }
      }
    }

    .change-role-office {
      .modal-field-content {
        padding: 60px 150px;
        min-height: 470px;
        max-height: 550px;
        overflow-y: auto;
      }
    }
  }

  .content-dropdown-version::-webkit-scrollbar {
    display: none !important;
  }

  .action {
    width: 20%
  }

  .column-preview {
    padding: 10px;
    border: 1px solid $blue_main ;
    border-radius: 10px;
    margin: 20px;
    max-height: 600px;
    min-height: 450px;
    overflow-y: scroll;
    position: relative;

    .preview-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.document-row {
  .icon-caret-down {
    svg {
      color: #808080;
      transform: rotateZ(0deg);
      transition: all 0.3s ease-in-out;
    }
  }

  &.is-active {
    .icon-caret-down {
      svg {
        transform: rotateZ(180deg);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.blob {
  width: 34px;
  height: 34px;
  top: 1px;
  left: 1px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
  }
}
button {
  &.btn-save {
    border-radius: 18px !important;
    border: none !important;
    padding: 0px !important;
    height: 35px !important;
    width: 140px !important;
    font-size: 16px !important;
  }
  &.btn-back {
    border-radius: 18px !important;
    border: none !important;
    padding: 0px !important;
    height: 35px !important;
    width: 140px !important;
    font-size: 16px !important;
    color: $btn_back !important;
  }
}
table {
  .input-tags {
    width: 20%
  }
}

.service-user-name {
  .name {
    white-space: normal;
    word-break: break-all;
  }
}

@media screen and (min-width: 769px) {
  .column-preview {
    max-height: 600px;
    min-height: 450px;
  }
  .level-right {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .page-list-data {
    .columns {
      .column:first-child,
      .column:nth-child(2) {
        padding: 10px !important;
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .title-doc-b2b {
    padding: 10px 20px;
  }
  .page-main-content {
    padding: 40px 20px;
  }
}
@media screen and (max-width: 495px) {
  .content-modal-change-status {
    label {
      white-space: normal;
      text-align: center
    }
  }
  button {
    &.is-action.action-success {
      padding: 0 !important;
    }
  }
  .button {
    &.btn-change-page-range {
      padding: 0 0 0 15px !important
    }

    &.btn-save-small {
      padding: 0 !important;
    }

    &.btn-delete-small {
      padding: 0 !important;
    }
  }
  .input-text {
    min-width: 150px !important
  }
  .data-table th {
    &.col-checkbox {
      min-width: 50px !important;
    }
  }
  .page-main-content {
    .columns {
      .column {
        .field {
          margin-top: 20px !important;
        }
      }
    }

    .setting-role {
      padding-top: 20px !important;
    }
  }
  .modal {
    .modal-card {
      width: 90% !important;
      margin: auto;

      .modal-card-head {
        padding-top: 10px;
        padding-bottom: 10px;

        .input_title {
          font-size: 16px
        }
      }

      .modal-card-body {
        .modal-field-content {
          padding-top: 10px !important;

          .columns {
            padding-top: 5px !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }

        .modal-buttons-footer {
          margin-top: 20px !important;
        }
      }
    }
  }

}
@media screen and (max-width: 1366px) {
  .change-role-office {
    .modal-field-content {
      padding: 40px 50px !important;
    }
  }
}
@media screen and (min-device-width: 800px) and (max-device-width: 1280px) {
  .input-title {
    font-size: 15px;
  }
  .modal {
    .modal-card {
      max-width: 1024px;
      width: 100%;
      border-radius: 25px;

      .modal-card-body {
        min-height: 400px;
        overflow: hidden;
        .modal-field-content {
          .dropdown-content {
            max-height: 200px;
            overflow-y: scroll;
          }
        }
      }
    }
  }
}

</style>
